// extracted by mini-css-extract-plugin
export var fadeIn = "styles-module--fadeIn--xAJbw";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--izyBx";
export var m = "styles-module--m--GzDpu";
export var s16 = "styles-module--s16--sOcq6";
export var s24 = "styles-module--s24--Llasi";
export var s32 = "styles-module--s32--c8fJI";
export var s32f = "styles-module--s32f--7WYkA";
export var s40 = "styles-module--s40--kC6pN";
export var s48 = "styles-module--s48--Omo0W";
export var scaleUp = "styles-module--scaleUp--YeH+B";
export var slideIn = "styles-module--slideIn--3Wqsg";