import React from "react";
import * as styles from "./styles.module.scss";
import RichText from "../../components/RichText";
import SiteGutter from "../../components/SiteGutter";
import SiteGrid from "../../components/SiteGrid";

function Banner({ data }) {
  return (
    <div className={styles.container}>
      <SiteGutter>
        <SiteGrid>
          <div className={styles.wrapper}>
            <RichText value={data.description} className={styles.description} />
            {data.cta?.url && (
              <a href={data.cta.url} className={styles.link}>
                {data.cta.label}
              </a>
            )}
          </div>
        </SiteGrid>
      </SiteGutter>
    </div>
  );
}

export default Banner;
