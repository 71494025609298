import React from "react";
import * as styles from "./styles.module.scss";
import cx from "classname";

export default function Headline({
  children,
  type,
  tagType,
  className,
  ...otherProps
}) {
  const classes =
    typeof type === "string" ? [styles[type]] : type.map((cls) => styles[cls]);
  return React.createElement(tagType, {
    children,
    className: cx(className, ...classes),
    ...otherProps
  });
}

Headline.defaultProps = {
  tagType: "h2",
  type: "s24"
};
