// extracted by mini-css-extract-plugin
export var bottomRightOrb = "styles-module--bottomRightOrb--5WGyC";
export var button = "styles-module--button--roeOI";
export var container = "styles-module--container--MJowG";
export var fadeIn = "styles-module--fadeIn--2lWGr";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--8x3mS";
export var featuredArticlesContainer = "styles-module--featuredArticlesContainer--Zsr4j";
export var headline = "styles-module--headline--akYch";
export var leftOrb = "styles-module--leftOrb--wAhfe";
export var restOfArticlesContainer = "styles-module--restOfArticlesContainer--dDu2w";
export var scaleUp = "styles-module--scaleUp--Uu9gq";
export var slideIn = "styles-module--slideIn--Pv3mK";
export var topRightOrb = "styles-module--topRightOrb--B7692";