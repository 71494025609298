import React, { useEffect, useState } from "react";
import BasePage from "../components/BasePage";
import useAnalytics, { pageView } from "../effects/useAnalytics";
import Banner from "./Banner";
import Grid from "./Grid";
import * as styles from "./styles.module.scss";
import Headline from "../components/Headline";
import FeaturedArticle from "./FeaturedArticle";
import { chunk } from "lodash-es";
import cx from "classname";

import FeaturedOrb from "./assets/Featured Orb.svg";
import MobileFeaturedOrb from "./assets/Mobile Featured Orb.svg";
import LeftArticlesOrb from "./assets/Left Articles Orb.svg";
import RightArticlesOrb from "./assets/Right Articles Orb.svg";
import MobileRightArticlesOrb from "./assets/Mobile Right Articles Orb.svg";

function useArticles(articleSlice) {
  const articles = Object.values(articleSlice);
  const featuredArticles = articles.splice(0, 3);
  const restOfArticles = chunk(articles, 2);
  const [maxIndexChunkToShow, setIndexChunkToShow] = useState(
    Math.min(restOfArticles.length, 2)
  );

  return {
    featuredArticles,
    restOfArticles: restOfArticles.slice(0, maxIndexChunkToShow),
    loadMore: () => setIndexChunkToShow((index) => index + 1),
    hasMoreArticles: maxIndexChunkToShow < restOfArticles.length
  };
}

function Press({ pageContext }) {
  const analytics = useAnalytics();
  const { featuredArticles, restOfArticles, loadMore, hasMoreArticles } =
    useArticles(pageContext.slices.Articles);
  useEffect(() => {
    pageView(`Press Page Viewed`);
    analytics.track(`Press Page Viewed`);
  }, [analytics]);

  return (
    <BasePage pageContext={pageContext}>
      <h1 className="srOnly">Expert Opinions</h1>
      <div className={styles.container}>
        <img
          src={FeaturedOrb}
          alt=""
          className={cx(styles.topRightOrb, "desktopOnly")}
        />
        <img
          src={MobileFeaturedOrb}
          alt=""
          className={cx(styles.topRightOrb, "mobileOnly")}
        />
        <Headline type="s32" className={styles.headline}>
          {pageContext.slices.headline}
        </Headline>
        <FeaturedArticle data={pageContext.slices.FeaturedPress} />
        <div className={styles.featuredArticlesContainer}>
          <Grid data={featuredArticles} />
        </div>
        <Banner data={pageContext.slices.Banner} />
        <div className={styles.restOfArticlesContainer}>
          <img src={LeftArticlesOrb} alt="" className={styles.leftOrb} />
          {restOfArticles.map((articles, index) => (
            <Grid data={articles} key={`grid-${index}`} />
          ))}
          <img
            src={RightArticlesOrb}
            alt=""
            className={cx(styles.bottomRightOrb, "desktopOnly")}
          />
          <img
            src={MobileRightArticlesOrb}
            alt=""
            className={cx(styles.bottomRightOrb, "mobileOnly")}
          />
        </div>
        {hasMoreArticles && (
          <button
            className={cx("blue button", styles.button)}
            onClick={loadMore}
          >
            {pageContext.slices.loadMore}
          </button>
        )}
      </div>
    </BasePage>
  );
}

export default Press;
