import React from "react";
import * as styles from "./styles.module.scss";
import SiteGutter from "../../components/SiteGutter";
import SiteGrid from "../../components/SiteGrid";
import Article from "../Article";
import { kebabCase } from "lodash-es";

function Grid({ data }) {
  return (
    <SiteGutter>
      <SiteGrid>
        <div className={styles.grid}>
          {data.map((article) => (
            <Article
              data={article}
              key={kebabCase(`${article.source} ${article.headline}`)}
            />
          ))}
        </div>
      </SiteGrid>
    </SiteGutter>
  );
}

export default Grid;
