import React from "react";
import Headline from "../../components/Headline";
import RichText from "../../components/RichText";
import Link from "../../components/Link";
import * as styles from "./styles.module.scss";
import cx from "classname";

function Article({ data, featured = false }) {
  return (
    <div className={cx(styles.container, { [styles.featured]: featured })}>
      {featured === true && (
        <div className={styles.imageContainer}>
          <div
            className={styles.image}
            style={{ backgroundImage: `url(${data.image?.image?.file.url})` }}
          ></div>
        </div>
      )}
      <div
        className={cx(styles.infoContainer, { [styles.featured]: featured })}
      >
        <Headline className={styles.source}>{data.source}</Headline>
        <div>
          <Headline className={styles.headline}>{data.headline}</Headline>
          <RichText className={styles.description} value={data.body} />
        </div>
        <Link
          url={data.cta.url}
          className={cx({
            [cx("button blue", styles.button)]: featured,
            [styles.link]: !featured
          })}
          target="_blank"
          rel="noopener noreferrer"
        >
          {data.cta.label} {!featured && <Chevron />}
        </Link>
      </div>
    </div>
  );
}

function Chevron() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.chevron}
    >
      <path
        d="M10 17L16 12.0027L10 7"
        stroke="#326F82"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Article;
