import React from "react";
import SiteGutter from "../../components/SiteGutter";
import SiteGrid from "../../components/SiteGrid";
import Article from "../Article";

function FeaturedArticle({ data }) {
  return (
    <SiteGutter>
      <SiteGrid>
        <Article data={data} featured />
      </SiteGrid>
    </SiteGutter>
  );
}

export default FeaturedArticle;
